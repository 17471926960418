.asa-calendar.react-calendar {
  border: 0px;
  width: 100%;
  color: #1c1c1c;
  font-weight: 400;
  font-size: 16px;
}

.asa-calendar .react-calendar__navigation {
  height: 56px;
  margin-bottom: 0;
}
.asa-calendar .react-calendar__navigation__arrow {
  min-width: 24px;
}
.asa-calendar .react-calendar__navigation__arrow div {
  margin: 0 auto;
}
.asa-calendar .react-calendar__navigation__arrow[disabled] {
  background-color: initial;
  opacity: 0.5;
  cursor: not-allowed;
}
.asa-calendar .react-calendar__navigation__arrow svg {
  width: 20px;
}

/* Calendar day tiles container */
.asa-calendar .react-calendar__viewContainer {
  margin-top: 4px;
  border-top: 1px solid #dadada;
}
/* Weekdays tile */
.asa-calendar .react-calendar__month-view__weekdays__weekday {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1c1c1c;
  padding: 0;
  font-weight: 400;
}
/* Weekdays tile content */
.asa-calendar .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

/* Default day tile */
button.asa-calendar-tile {
  width: 44px;
  min-height: 44px;
  color: #1c1c1c;
  border-width: 0;
}
/* Weekend day tile */
button.asa-calendar-tile.react-calendar__month-view__days__day--weekend {
  color: #f81b19;
}
/* Current day tile */
button.asa-calendar-tile.react-calendar__tile--now {
  background-color: transparent;
  border: 1px solid #1c1c1c;
}

/* Active day tile */
button.asa-calendar-tile.react-calendar__tile--active {
  background-color: #f4f4f4;
  /* color: #1D1D1E; */
}

/* Day tiles that are the extremes of a range or tilles above days that has active/selected elements under */
button.asa-calendar-tile.react-calendar__tile--rangeStart,
button.asa-calendar-tile.react-calendar__tile--rangeEnd,
button.asa-calendar-tile.react-calendar__tile--rangeBothEnds,
button.asa-calendar-tile.react-calendar__tile--hasActive,
button.asa-calendar-tile.react-calendar__tile--active:focus {
  background-color: #1c1c1c;
  color: #fff;
}

/* Hover state for tile */
button.asa-calendar-tile.react-calendar__tile:hover:not([disabled]),
button.asa-calendar-tile.react-calendar__tile--active:hover {
  background-color: #5b5b5b;
  color: #fff;
}

/* Day tiles that are out of current month */
button.asa-calendar-tile.react-calendar__month-view__days__day--neighboringMonth {
  color: #6e6e6e;
}
/* Weekend day tiles that are out of current month */
button.asa-calendar-tile.react-calendar__month-view__days__day--neighboringMonth.react-calendar__month-view__days__day--weekend {
  color: #fd9691;
}

/* Day tiles that are disabled */
button.asa-calendar-tile[disabled] {
  background-color: #f9f9f9;
}
/* Day tiles that are disabled due to max or min date restriction */
button.asa-calendar-tile[disabled].max-min_restricted-date {
  color: #b6b6b6;
}
/* Weekend day tiles that are disabled due to custom restriction */
button.asa-calendar-tile.react-calendar__month-view__days__day--weekend[disabled].custom_restricted-date {
  color: #fd9691;
  background-color: #fff;
}
/* Weekend day tiles that are disabled due to max or min date restriction */
button.asa-calendar-tile.react-calendar__month-view__days__day--weekend[disabled].max-min_restricted-date {
  background-color: #f9f9f9;
  color: #fd9691;
}

/* Disable pointer events for controlled calendars */
.asa-calendar.controlled
  button.asa-calendar-tile.react-calendar__month-view__days__day {
  cursor: default;
  pointer-events: none;
}
